<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.maintenanceItemsConfiguration')"
    id="maintenanceItemsConfiguration"
  >
    <div id="outer-title">
      {{ $t("i18n.maintenanceItemsConfiguration") }}
    </div>
    <el-button class="el-button--add" @click="dialogVisible = true">
      {{ $t("i18n.codeLocManage") }}
    </el-button>
    <jl-form
      class="m-t"
      :columns="formColumns"
      inline="true"
      :option="formOption"
    ></jl-form>
    <p class="m-t">{{ $t("i18n.eleRemark1") }}</p>
    <p>{{ $t("i18n.eleRemark2") }}</p>
    <p>{{ $t("i18n.eleRemark3") }}</p>
    <div class="width" style="width: 600px">
      <jl-table
        :tableData="tableData"
        :options="tableOptions"
        :columns="tableColumns"
        :operates="operates"
        height="500"
      ></jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.codeLocManage')"
      v-model="dialogVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-table
        :tableData="codeData"
        @handleEdit="handleEdit"
        @init="getCodeData"
        :options="codeOptions"
        :columns="codeColumns"
        :operates="codeOperates"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  name: "MaintenanceItemsConfigselect",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      formColumns: [
        {
          prop: "search",
          label: "elevatorType",
          type: "select",
          value: "0",
          isUpdate: true,
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "elevator_type",
          change: (val) => {
            state.type = val;
          },
        },
      ],
      type: "0",
      formOption: [
        {
          show: false,
        },
      ],
      tableData: [],
      tableOptions: {
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      tableColumns: [
        {
          prop: "code",
          label: "templateType",
          align: "center",
          formatter: (row) => {
            return t("i18n." + row.code);
          },
        },
        {
          label: "operation",
          align: "center",
          formatter: () => {
            return `<a><i class="el-icon-view"></i>&nbsp;
              ${t("i18n.maintenanceConfiguration")}</a>`;
          },
          method: (row) => {
            if (state.type) {
              router.push({
                path: "/maintenance/maintenanceItemsConfigitemInfo",
                query: {
                  companyId: route.query.id,
                  elevatorType: state.type,
                  maintenanceType: row.value,
                },
              });
            } else {
              proxy.$defined.tip(t("i18n.select") + t("i18n.elevatorType"));
            }
          },
        },
        {
          label: "operation",
          align: "center",
          formatter: (row) => {
            if (
              proxy.$defined.btnPermission("基础模板") &&
              parseInt(row.value) < 40
            ) {
              return `<a><i class="el-icon-view"></i>&nbsp;
                ${t("i18n.ModeJoylive")}</a>`;
            } else {
              return "";
            }
          },
          method: (row) => {
            setAllItemMode(row.value);
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      dialogVisible: false,
      codeOptions: {
        isAdd: true, // 是否启用新增功能
        isEdit: true, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      codeColumns: [
        {
          prop: "id",
          rows: false,
          row: false,
        },
        {
          prop: "signName",
          label: "codeLocation",
          align: "center",
          search: false,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          label: "delete",
          align: "center",
          row: false,
          formatter: (row) => {
            if (row.isUsed == 0) {
              return `<a><i class="el-icon-delete"></i>&nbsp;
                ${t("i18n.delete")}</a>`;
            } else {
              return t("i18n.usedNotDelete");
            }
          },
          method: (row) => {
            handleDelete(row);
          },
        },
      ],
      codeOperates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      codeData: [],
      billModelData: [],
    });

    const init = () => {
      setData();
    };

    const getBillModelData = () => {
      proxy.$api.common.getLineageByCode("billModel").then((res) => {
        state.billModelData = res.data.lineages;
      });
    };
    getBillModelData();

    const setData = () => {
      let arr = [];
      proxy.$api.common.getLineageByCode("billModel").then((res) => {
        res.data.lineages.map((item) => {
          if (parseInt(item.value) < 40 || parseInt(item.value) > 60) {
            arr.push(item);
          }
        });
        state.tableData = arr;
      });
      
    };

    const getCodeData = async () => {
      let id = route.query.id;
      const { data } = await proxy.$api.maintenance.getConfigAign(id);
      state.codeData = data;
    };

    const setAllItemMode = (id) => {
      // 基础模板
      proxy.$defined.confirm(() => {
        proxy.$api.maintenance
          .setItemMode({
            companyId: route.query.id,
            elevatorType: state.type,
            maintenanceType: id,
          })
          .then(() => {
            proxy.$defined.tip(t("i18n.success"), "success");
          });
      }, t("i18n.useMode"));
    };

    const handleEdit = async (val) => {
      // 新增/编辑数据
      if (validatePass(val.signName)) {
        let msg = val.id ? "edit" : "new";
        val.companyId = route.query.id;
        val.id
          ? await proxy.$api.maintenance.editConfigAign(val.id, val)
          : await proxy.$api.maintenance.addConfigAign(val);
        proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
        getCodeData();
      }
    };

    const validatePass = (val) => {
      // 检验新增或编辑名称是否存在
      let current = state.codeData.filter((_x) => {
        return _x.signName == val;
      });
      let req = /^([\u4e00-\u9fa5]{2,4}|[a-zA-Z]{2,6})$/g; //2-4个汉字 或者 字母数字组合2-6个字符
      if (!req.test(val)) {
        proxy.$defined.tip(t("i18n.qrFormat"));
        return false;
      } else if (current.length > 0) {
        proxy.$defined.tip(t("i18n.codeLocExist"));
        return false;
      } else {
        return true;
      }
    };

    const handleDelete = (row) => {
      proxy.$defined.confirm(
        () => {
          proxy.$api.maintenance.delConfigAign(row.id).then(() => {
            getCodeData();
            proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
          });
        },
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };

    init();

    return {
      ...toRefs(state),
      getCodeData,
      handleEdit,
      handleDelete,
      setAllItemMode,
    };
  },
};
</script>

<style lang="scss">
#maintenanceItemsConfiguration {
  p {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  #maintenanceItemsConfiguration {
    .width {
      width: 100% !important;
    }
  }
}
</style>
